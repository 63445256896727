<template>
    <div class="px-3 py-2"> 
        <v-form
            ref="forinv"
            lazy-validation
        >
        <b-sidebar id="add_invoice" aria-labelledby="sidebar-no-header-title" no-header backdrop width="90rem" :style="`direction:`+$parent.lang.ldir" right :title="$parent.lang.add_direct_invoice" shadow >
    <template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:140px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>{{$parent.lang.add_invoice}}</span>
        </div>
        <div @click="hide" id="HideInvoice" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>{{$parent.lang.close}}</span>
      </div>
    </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <div v-if="showClientTypeRow" class="tobbuttom backBlack" :style="`direction:${$parent.lang.dir};padding:7px;width:100%;text-align:center;`">
                <b-button :style="`border-radius:0;background:transparent;color:#fff;border:none;`">{{$parent.lang.customer_type}}</b-button>
                <b-button style="margin-inline-end:5px;font-weight: bolder;width:150px;font-size:1.1rem !important;margin-inline-end:5px;border:2px solid #fff !important" id="_cInd" :class="`dispFlex btn btn-small redColor borderRad_`+$parent.lang.algin" @click="changeCusType(0)" >{{$parent.lang.individual_client}} <i id="_indiv"  style="font-size:1.1rem !important;margin-inline-start:10px;" class="fas fa-arrow-down"></i></b-button>
                <b-button style="margin-inline-end:5px;font-weight: bolder;width:150px;font-size:1.1rem !important;border:2px solid #fff !important" id="_cCom" :class="`dispFlex btn btn-small weightColor borderRad_none`" @click="changeCusType(1)" >{{$parent.lang.company_clinet}} <i id="_comps"  style="font-size:1.1rem !important;margin-inline-start:10px;" class="fas fa-arrow-down hideme"></i></b-button>
                <b-button style="margin-inline-end:5px;font-weight: bolder;width:150px;font-size:1.1rem !important;border:2px solid #fff !important" id="_cGov" :class="`dispFlex btn btn-small weightColor borderRad_`+$parent.lang.lalgin" @click="changeCusType(2)" >{{$parent.lang.government_clinet}} <i id="_goves" style="font-size:1.1rem !important;margin-inline-start:10px;" class="fas fa-arrow-down hideme"></i></b-button>
            </div>
            <v-row>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{$parent.lang.invoice_type}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.invtype"
                        :options="invoice.invtypes"
                        :label="$parent.lang.invoice_type"
                        @change="InvType()"
                        style="background:green !important"
                        ></b-form-select>
                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{$parent.lang.vat_type}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.vtype"
                        :options="vattype"
                        :label="$parent.lang.vat_type"
                        @change="changeVatType()"
                        style="background:red !important"
                        ></b-form-select>
                        <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{$parent.lang.payment_method}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.paytype"
                        :options="invoice.paytypes"
                        :label="$parent.lang.payment_method"
                        @change="changePtype()"
                        ></b-form-select>
                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{$parent.lang.saler_name}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.userid"
                        :options="invoice.users"
                        :label="$parent.lang.saler_name"
                        ></b-form-select>
                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12" v-if="$store.state.caneditinvoicedate">
                    <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="invoice.date"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <label>{{$parent.lang.invoice_date}}</label>
                        <b-form-input
                            id="input-live"
                            v-model="invoice.date"
                            aria-describedby="input-live-help input-live-feedback"
                            prepend-icon="mdi-calendar"
                            :placeholder="$parent.lang.invoice_date"
                            readonly
                            trim
                            v-bind="attrs"
                            v-on="on"
                            class="input-sm inborder"
                        ></b-form-input>
                        
                        </template>
                        <v-date-picker
                        v-model="date"
                        scrollable
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="modal = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
                <v-col cols="12" md="3" sm="6" xs="12" v-else>
                    <label>{{$parent.lang.invoice_date}}</label>
                    <b-form-input
                        class="inborder"
                            v-model="invoice.date"
                            :label="$parent.lang.invoice_date"
                            prepend-icon="mdi-calendar"
                            readonly
                        >{{ invoice.date }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12" v-if="$store.state.licenseType.cars && $store.state.licenseType.showCarPlate">

                    <label>{{$parent.lang.carsnumber}}</label>
                    <b-input-group>
                        <b-form-select
                            class="selborder"
                            v-model="invoice.carsnumber"
                            :options="carsNums"
                            @change="updateCarsCount()"
                            style="background:rergb(255, 136, 0) !important"
                            ></b-form-select>
                        <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
            </v-row> 
            
            <v-row v-if="customer.cctype == 1 && ($store.state.licenseType.cars || $store.state.licenseType.bill)">
                <v-col cols="12" md="1" sm="12" style="display:none !important;">
                    <label>{{$parent.lang.invoiceno}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.invoiceno"
                        v-model="invoice.billid" 
                        :rules="fieldRules"
                        required
                        readonly
                    >{{ invoice.billid }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12" v-if="$store.state.licenseType.showIDNumber">
                    <label>{{$parent.lang.customerid}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.customerid"
                        v-model="customer.customerid"
                        size="10"
                        id="CusMobile"
                        @change="getAllNameById(0)"
                        >{{ customer.mobile }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <label>{{$parent.lang.mobile}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.mobile"
                        v-model="customer.mobile"
                        size="10"
                        id="CusMobile"
                        @change="getName(1)"
                        >{{ customer.mobile }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <label>{{$parent.lang.id_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.id_number"
                        v-model="customer.id_number"
                        size="10"
                        id="CusMobile"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="8" xs="12" style="display:none;">
                    <label>{{$parent.lang.chose_customer}}</label>
                    <b-input-group>
                        <b-form-select
                            class="selborder"
                            v-model="customer.id"
                            :options="customerLists"
                            @change="selectCustomer()"
                            style="background:red !important"
                            ></b-form-select>
                        <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="8" xs="12">
                    <label>{{$parent.lang.customer_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.customer_name"
                        v-model="customer.full_name"
                        id="CusMobile"
                        >{{ customer.full_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="8" xs="12" v-if="$store.state.licenseType.showOwnerName">
                    <label>{{$parent.lang.owner_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.owner_name"
                        v-model="invoice.owner_name"
                        >{{ invoice.owner_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{$parent.lang.work_type}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.work_type"
                        :options="workTypes"
                        :label="$parent.lang.invoice_type"
                        @change="InvType()"
                        style="background:green !important"
                        ></b-form-select>
                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
            </v-row>
            <v-row v-if="$store.state.licenseType.parts">
                <v-col cols="12" md="2" sm="12" style="display:none !important;">
                    <label>{{$parent.lang.invoiceno}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.invoiceno"
                        v-model="invoice.billid" 
                        :rules="fieldRules"
                        required
                        readonly
                    >{{ invoice.billid }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <label>{{$parent.lang.customerid}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.customerid"
                        v-model="customer.customerid"
                        size="10"
                        @change="getName()"
                        >{{ customer.customerid }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <label>{{$parent.lang.mobile}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.mobile"
                        v-model="customer.mobile"
                        :rules="fieldRules"
                        size="10"
                        id="CusMobile"
                        @change="getName()"
                        >{{ customer.mobile }}</b-form-input>
                </v-col>
                <v-col cols="12" md="4" sm="8" xs="12">
                   <label>{{$parent.lang.customer_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.customer_name"
                        v-model="customer.full_name"
                        :rules="fieldRules"
                        >{{ customer.full_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <label>{{$parent.lang.model}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.model"
                        v-model="car.model"
                        :rules="fieldRules"
                        >{{ car.model }}</b-form-input>
                </v-col>
                
            </v-row>
            <v-row v-if="showcamp && customer.cctype == 2">
                <v-col cols="12" md="1" sm="12" style="display:none !important;">
                    <label>{{$parent.lang.invoiceno}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.invoiceno"
                        v-model="invoice.billid" 
                        :rules="fieldRules"
                        required
                        readonly
                    >{{ invoice.billid }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12" v-if="$store.state.licenseType.showIDNumber">
                    <label>{{$parent.lang.customerid}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.customerid"
                        v-model="customer.customerid"
                        size="10"
                        id="CusMobile"
                        @change="getAllNameById(0)"
                        >{{ customer.customerid }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <label>{{$parent.lang.mobile}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.mobile"
                        v-model="customer.mobile"
                        size="10"
                        id="CusMobile"
                        @change="getName(1)"
                        >{{ customer.mobile }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="8" xs="12" style="display:none;">
                    <label>{{$parent.lang.chose_company}}</label>
                    <b-input-group>
                        <b-form-select
                            class="selborder"
                            v-model="customer.id"
                            :options="customerLists"
                            @change="selectCustomer()"
                            style="background:red !important"
                            ></b-form-select>
                        <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="8" xs="12" style="display:none;">
                    <label>{{$parent.lang.customer_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.customer_name"
                        v-model="customer.full_name"
                        id="CusMobile"
                        >{{ customer.full_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12" v-if="$store.state.licenseType.showproject">
                    <label>{{$parent.lang.projectname}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.projectname"
                        v-model="company.projectname"
                        >{{ company.projectname }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12" xs="12">
                     <label>{{$parent.lang.company_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.company_name"
                        v-model="company.fullname"
                        style="background:#fff700 !important;"
                        />
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12">
                    <label>{{$parent.lang.company_vatid}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.company_vatid"
                        v-model="company.vatid"
                        >{{ company.fullname }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12">
                    <label>{{$parent.lang.crt_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.crt_number"
                        v-model="company.crt_number"
                        style=""
                        />
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{$parent.lang.street_name}}</label>
                    <b-form-input
                    class="inborder"
                    :label="$parent.lang.street_name"
                    v-model="company.street_name"
                    style="background:#d3ffd3 !important"
                    >{{ company.street_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="1" sm="12">
                    <label>{{$parent.lang.building_no}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.building_no"
                        v-model="company.building_no"
                        style="background:#d3ffd3 !important"
                        >{{ company.building_no }}</b-form-input>
                </v-col>
                <v-col cols="12" md="1" sm="12">
                    <label>{{$parent.lang.branch_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.branch_number"
                        v-model="company.branch_number"
                        style="background:#d3ffd3 !important"
                        >{{ company.branch_number }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{$parent.lang.district}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.district"
                        v-model="company.district"
                        style="background:#d3ffd3 !important"
                        >{{ company.district }}</b-form-input>
                </v-col>
                <v-col cols="12" md="1" sm="12">
                    <label>{{$parent.lang.zipcode}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.zipcode"
                        v-model="company.zipcode"
                        style="background:#d3ffd3 !important"
                        >{{ company.zipcode }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{$parent.lang.city}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.city"
                        v-model="company.city"
                        style="background:#d3ffd3 !important"
                        >{{ company.city }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12" xs="12">
                    <label>{{$parent.lang.comapny_address}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.comapny_address"
                        v-model="company.address"
                        style="background:#fff700 !important;"
                        />
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{$parent.lang.work_type}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.work_type"
                        :options="workTypes"
                        :label="$parent.lang.invoice_type"
                        @change="InvType()"
                        style="background:green !important"
                        ></b-form-select>
                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
            </v-row>
            <v-row v-if="showGov && customer.cctype == 3">
                <v-col cols="12" md="1" sm="12" style="display:none !important;">
                    <label>{{$parent.lang.invoiceno}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.invoiceno"
                        v-model="invoice.billid" 
                        :rules="fieldRules"
                        required
                        readonly
                    >{{ invoice.billid }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12" v-if="$store.state.licenseType.showIDNumber">
                    <label>{{$parent.lang.customerid}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.customerid"
                        v-model="customer.customerid"
                        size="10"
                        id="CusMobile"
                        @change="getAllNameById(0)"
                        >{{ customer.mobile }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <label>{{$parent.lang.mobile}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.mobile"
                        v-model="customer.mobile"
                        size="10"
                        id="CusMobile"
                        @change="getName(1)"
                        >{{ customer.mobile }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="8" xs="12" style="display:none;">
                    <label>{{$parent.lang.chose_entity}}</label>
                    <b-input-group>
                        <b-form-select
                            class="selborder"
                            v-model="customer.id"
                            :options="customerLists"
                            @change="selectCustomer()"
                            style="background:red !important"
                            ></b-form-select>
                        <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="8" xs="12" style="display:none;">
                    <label>{{$parent.lang.customer_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.customer_name"
                        v-model="customer.full_name"
                        id="CusMobile"
                        >{{ customer.full_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12" xs="12">
                    <label>{{$parent.lang.entity_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.entity_name"
                        v-model="company.entity_name"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12">
                    <label>{{$parent.lang.branch_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.branch_name"
                        v-model="company.branch_name"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12">
                    <label>{{$parent.lang.request_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.request_number"
                        v-model="company.request_number"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12">
                    <label>{{$parent.lang.order_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.order_number"
                        v-model="company.order_number"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12">
                    <label>{{$parent.lang.po_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.po_number"
                        v-model="company.po_number"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12">
                    <label>{{$parent.lang.po_date}}</label>
                    <v-dialog
                        ref="dialogd"
                        v-model="modal"
                        :return-value.sync="company.po_date"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <!-- <label>{{$parent.lang.po_date}}</label> -->
                        <b-form-input
                            id="input-live"
                            v-model="company.po_date"
                            aria-describedby="input-live-help input-live-feedback"
                            prepend-icon="mdi-calendar"
                            :placeholder="$parent.lang.po_date"
                            readonly
                            trim
                            v-bind="attrs"
                            v-on="on"
                            class="input-sm inborder"
                        ></b-form-input>
                        
                        </template>
                        <v-date-picker
                        v-model="date"
                        scrollable
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="modal = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialogd.save(date)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{$parent.lang.work_type}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.work_type"
                        :options="workTypes"
                        :label="$parent.lang.invoice_type"
                        @change="InvType()"
                        style="background:green !important"
                        ></b-form-select>
                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
            </v-row>
            <div  v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo">
                <v-row v-for="(item,index) in cars" :key="index">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" md="2" sm="12">
                                <!-- <label v-if="$store.state.licenseType.carInfoRequired" style="color:red">{{$parent.lang.plate_number}}</label> -->
                                <label>{{$parent.lang.plate_number}}</label>
                                <b-form-input
                                    v-if="$store.state.licenseType.carInfoRequired"
                                    class="inborder"
                                    :label="$parent.lang.plate_number"
                                    v-model="cars[index].plate_number" 
                                    @change="getCar(cars[index].plate_number,index)"
                                    style="background:#fff700 !important;"
                                />
                                <b-form-input
                                    v-else
                                    class="inborder"
                                    :label="$parent.lang.plate_number"
                                    v-model="cars[index].plate_number" 
                                    @change="getCar(cars[index].plate_number,index)"
                                />
                            </v-col>
                            <v-col cols="12" md="2" sm="8" xs="12">
                                <label>{{$parent.lang.brand_name}}</label>
                                <b-form-input
                                    class="inborder"
                                    :label="$parent.lang.brand"
                                    v-model="cars[index].brand"
                                    ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="2" sm="4" xs="12">
                                <!-- <label v-if="$store.state.licenseType.carInfoRequired" style="color:red">{{$parent.lang.car_model}}</label> -->
                                <label>{{$parent.lang.car_model}}</label>
                                <b-form-input
                                    v-if="$store.state.licenseType.carInfoRequired"
                                    class="inborder"
                                    :label="$parent.lang.car_model"
                                    v-model="ecars[index].model"
                                    style="background:#fff700 !important;"
                                    />
                                <b-form-input
                                    v-else
                                    class="inborder"
                                    :label="$parent.lang.car_model"
                                    v-model="cars[index].model"
                                    />
                            </v-col>
                            <v-col cols="12" md="1" sm="8" xs="12">
                                <!-- <label v-if="$store.state.licenseType.carInfoRequired" style="color:red">{{$parent.lang.car_year}}</label> -->
                                <label>{{$parent.lang.car_year}}</label>
                                <b-form-input
                                    v-if="$store.state.licenseType.carInfoRequired"
                                    class="inborder"
                                    :label="$parent.lang.car_year"
                                    v-model="cars[index].made_year"
                                    style="background:#fff700 !important;"
                                    />
                                <b-form-input
                                    v-else
                                    class="inborder"
                                    :label="$parent.lang.car_year"
                                    v-model="cars[index].made_year"
                                    />
                            </v-col>
                            <v-col cols="12" md="1" sm="8" xs="12">
                                <label>{{$parent.lang.car_color}}</label>
                                <b-form-input
                                    class="inborder"
                                    :label="$parent.lang.car_color"
                                    v-model="cars[index].color"
                                    >{{ car.color }}</b-form-input>
                            </v-col>
                            <v-col cols="12" md="2" sm="8" xs="12">
                                <label>{{$parent.lang.vin_numebr}}</label>
                                <b-form-input
                                    class="inborder"
                                    :label="$parent.lang.vin_number"
                                    v-model="cars[index].vin"
                                    @input="toUpper(index)"
                                    ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="2" sm="8" xs="12">
                                <label>{{$parent.lang.engine_meter}}</label>
                                <b-form-input
                                    class="inborder"
                                    :label="$parent.lang.engine_meter"
                                    v-model="cars[index].engine_meter"
                                    ></b-form-input>
                            </v-col>
                            <!-- 
                            <v-col cols="12" md="2" sm="8" xs="12">
                                <label>{{$parent.lang.next_oil_change}}</label>
                                <b-form-input
                                    class="inborder"
                                    :label="$parent.lang.next_oil_change"
                                    v-model="cars[index].next_oil_change"
                                    ></b-form-input>
                            </v-col> -->
                        </v-row>
                    </v-col>
                    <!-- <v-col cols="2" v-if="index!=0">
                        <v-btn class="addCar" style="margin-top:28px;border: none !important;box-shadow: none !important;
                            background: red !important;color:#fff !important;width:100%" @click="deleteCar(index)">{{ $parent.lang.delete }} <i :class="`fas fa-times`"></i></v-btn>
                    </v-col> -->
                    <!-- <v-col cols="2" v-else>
                        <v-btn class="addCar" style="margin-top:28px;border: none !important;box-shadow: none !important;
                            background: green !important;color:#fff !important;width:100%;" @click="addNewCar()">{{ $parent.lang.add_car }} <i :class="`fas fa-arrow-${$parent.lang.lalgin}`"></i></v-btn>
                    </v-col> -->
                </v-row>
                <!-- <div>
                    <v-btn class="boDry" style="color:#fff;border: none;box-shadow: none;
                    background: green;margin-top:3px;font-size:.7rem;" @click="addNewCar()">{{ $parent.lang.add_car }}</v-btn>
                </div> -->
            </div>
            
            <v-row class="mt-5">
                <!-- <v-window>{{$parent.lang.invoice_details}}</v-window> -->
                <v-simple-table striped hover style="width:100%;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-center backBlack" v-if="$store.state.licenseType.cars && $store.state.licenseType.showCarPlate">{{$parent.lang.chose_car}}</th>
                                <th class="backBlack" v-if="$store.state.licenseType.showItemCode">{{$parent.lang.item_code}}</th>
                                <th style="width:20%" class="text-center backBlack" v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo">{{$parent.lang.ment_description}}</th>
                                <th style="width:20%" class="text-center backBlack" v-else>{{$parent.lang.description}}</th>
                                
                                <th v-if="$store.state.licenseType.showExtra" class="text-center backBlack">{{$parent.lang.width}}</th>
                                <th v-if="$store.state.licenseType.showExtra" class="text-center backBlack">{{$parent.lang.thickness}}</th>
                                <th v-if="$store.state.licenseType.showExtra" class="text-center backBlack">{{$parent.lang.limited}}</th>
                                
                                <th class="text-center backBlack">{{$parent.lang.qtty}}</th>
                                <th class="text-center backBlack">{{$parent.lang.item_price}}</th>
                                <th class="text-center backGreen">{{$parent.lang.total_without_vat}}</th>
                                <th class="text-center backRed">{{$parent.lang.vat}}</th>
                                <th class="text-center backBlack">{{$parent.lang.ftotal}}</th>
                                <th class="text-center backBlack" v-if="cardid == 0">{{$parent.lang.action}}</th>
                            </tr>
                        </thead>
                        <tbody id="tablerow">
                            <tr v-for="(item,index) in tbrows" :key="index">
                                <td v-if="$store.state.licenseType.cars && $store.state.licenseType.showCarPlate">{{ item.plate_number }}</td>
                                <td v-if="$store.state.licenseType.showItemCode">{{ item.itemcode }}</td>
                                <td><b-form-input v-model="tbrows[index].item_name" /></td>
                                <td v-if="$store.state.licenseType.showExtra"><b-form-input v-model="tbrows[index].extra1" /></td>
                                <td v-if="$store.state.licenseType.showExtra"><b-form-input v-model="tbrows[index].extra2" /></td>
                                <td v-if="$store.state.licenseType.showExtra"><b-form-input v-model="tbrows[index].extra3" /></td>
                                <td><b-form-input v-model="tbrows[index].qty" @change="changeVatType()" /></td>
                                <td><b-form-input v-model="tbrows[index].item_price" @change="changeVatType()" /></td>
                                <td class="text-center" style="background:lightgreen !important">{{ convertToComa(item.total) }}</td>
                                <td class="text-center" style="background:lightgreen !important">{{ convertToComa(item.vat) }}</td>
                                <td class="text-center">{{ convertToComa(item.ftotal) }}</td>
                                <td style="" v-if="cardid == 0">
                                    <b-button type="button" variant="danger" style="padding:0.15rem 0.5rem !important;font-weight:bolder;width:80px;background:red;border:none" @click="removeItem(index)">حذف</b-button>
                                </td>
                            </tr>
                            <tr v-if="cardid == 0">
                                <td v-if="$store.state.licenseType.cars && $store.state.licenseType.showCarPlate" style="width:150px;">
                                    <b-input-group>
                                    <b-form-select
                                        class="selborder"
                                        style="background:red !important"
                                        :options="currentCars"
                                        v-model="newrow.plate_number">
                                    </b-form-select>
                                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                        <i class="fas fa-arrow-down"></i>
                                        </b-input-group-append>
                                    </b-input-group>
                                </td>
                                <td style="width:150px;" v-if="$store.state.licenseType.showItemCode">
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.itemcode"
                                        @change="getProducts()"
                                        >
                                    </b-form-input>
                                </td>
                                <td>
                                    <b-form-input v-model="newrow.item_name" list="items-list1"></b-form-input>
                                    <datalist id="items-list1">
                                        <option v-for="(item,index) in itemsworkdes" :key="index">{{item}}</option>
                                    </datalist>
                                    
                                    <div>
                                        <b-form-textarea class="inborder" style="display:none !important;" v-model="newrow.description" />
                                    </div>
                                    
                                </td>
                                <td v-if="$store.state.licenseType.showExtra">
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.extra1"
                                    ></b-form-input>
                                </td>
                                <td v-if="$store.state.licenseType.showExtra">
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.extra2"
                                    ></b-form-input>
                                </td>
                                <td v-if="$store.state.licenseType.showExtra">
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.extra3"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.qty"
                                        @change="calcKit()"
                                        id="newitemqty"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.item_price"
                                        @change="calcKit()"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.total"
                                        @change="calcKit()"
                                        readonly
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.vat"
                                        @change="calcKit()"
                                        readonly
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.ftotal"
                                        @change="calcKit()"
                                        readonly
                                    ></b-form-input>
                                </td>
                                <td class="text-center" style="">
                                    <b-button type="button" class="btn-sm" variant="success" style="display:none;width:80px;font-weight:bolder;background:green;border:none;" @click="addNewRow()">{{$parent.lang.add}}</b-button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th v-if="$store.state.licenseType.cars && $store.state.licenseType.showCarPlate" class="text-center backEmpty"></th>
                                <th class="text-center backEmpty" v-if="$store.state.licenseType.showItemCode"></th>
                                <th class="text-center backEmpty"></th>
                                <th v-if="$store.state.licenseType.showExtra" class="text-center backEmpty"></th>
                                <th v-if="$store.state.licenseType.showExtra" class="text-center backEmpty"></th>
                                <th v-if="$store.state.licenseType.showExtra" class="text-center backEmpty"></th>
                                <th class="text-center backEmpty"></th>
                                <td class="text-center backRed"> {{$parent.lang.total_discount}}</td>
                                <td class="text-center backRed">
                                    <b-form-input
                                        class="inborder"
                                        v-model="invoice.discount"
                                        @change="calcAll()"
                                    ></b-form-input>
                                </td>
                                <td class="text-center backEmpty"></td>
                                <td class="text-center backEmpty"></td>
                                <th class="text-center backEmpty"></th>
                            </tr>
                            <tr>
                                <th class="text-center backEmpty" v-if="$store.state.licenseType.cars && $store.state.licenseType.showCarPlate"></th>
                                <th class="text-center backEmpty" v-if="$store.state.licenseType.showItemCode"></th>
                                <th class="text-center backEmpty"></th>
                                <th class="text-center backEmpty"></th>
                                <th v-if="$store.state.licenseType.showExtra" class="text-center backEmpty"></th>
                                <th v-if="$store.state.licenseType.showExtra" class="text-center backEmpty"></th>
                                <th v-if="$store.state.licenseType.showExtra" class="text-center backEmpty"></th>
                                <th class="text-center backBlack">{{$parent.lang.total}}</th>
                                <th class="text-center backGreen">{{ convertToComa(nsp.total) }}</th>
                                <th class="text-center backRed">{{ convertToComa(nsp.vat) }}</th>
                                <th class="text-center backBlack">{{ convertToComa(nsp.ftotal) }}</th>
                                <th class="text-center backEmpty" v-if="cardid == 0"></th>
                            </tr>
                            <!-- <tr>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"> تأمين</th>
                                <th class="text-center">
                                    <b-form-input
                                        class="inborder"
                                        v-model="invoice.insurance"
                                        @change="calcAll()"
                                        type="number"
                                    ></b-form-input>
                                </th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                            </tr>
                            <tr>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center">الاجمالي بعد التامين</th>
                                <th class="text-center">{{ full_total_in }}</th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                            </tr> -->
                            <tr v-if="invoice.invtype != 1 && full_total != 0">
                                <td class="backEmpty" v-if="$store.state.licenseType.cars && $store.state.licenseType.showCarPlate"></td>
                                <td class="backEmpty"></td>
                                <td class="backEmpty"></td>
                                <td class="backEmpty"></td>
                                <td v-if="$store.state.licenseType.showExtra" class="backEmpty"></td>
                                <td v-if="$store.state.licenseType.showExtra" class="backEmpty"></td>
                                <td v-if="$store.state.licenseType.showExtra" class="backEmpty"></td>
                                <td class="backEmpty" colspan="4" style="color:#000 !important;">
                                    <label>{{$parent.lang.payment_amount}}</label>
                                    <b-form-input
                                        class="inborder"
                                        :label="$parent.lang.payment_amount"
                                        v-model="invoice.payment.cash"
                                        style="background:#c3d9ff;width:150px"
                                        >{{ invoice.payment.cash }}</b-form-input>
                                </td>
                                <td v-if="cardid == 0"></td>
                            </tr>
                        </tfoot>
                        
                    </template>
                </v-simple-table>
            </v-row>


            <v-row class="mt-10" v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo">
                <v-simple-table striped hover style="width:100%;">
                        <thead>
                            <tr>
                                <th colspan="9" class="p-2 text-center">{{$parent.lang.add_spare_part}}</th>
                            </tr>
                        </thead>
                </v-simple-table>
                <br>
                <v-simple-table striped hover style="width:100%;margin-top:10px;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-center backBlack" v-if="$store.state.licenseType.cars && $store.state.licenseType.showCarPlate">{{$parent.lang.chose_car}}</th>
                                <th class="backBlack" v-if="$store.state.licenseType.showItemCode">{{$parent.lang.spare_part_item_code}}</th>
                                <th style="width:20%" class="text-center backBlack">{{$parent.lang.item_name}}</th>
                                
                                <th v-if="$store.state.licenseType.showExtra" class="text-center backBlack">{{$parent.lang.width}}</th>
                                <th v-if="$store.state.licenseType.showExtra" class="text-center backBlack">{{$parent.lang.thickness}}</th>
                                <th v-if="$store.state.licenseType.showExtra" class="text-center backBlack">{{$parent.lang.limited}}</th>
                                
                                <th class="text-center backBlack">{{$parent.lang.qtty}}</th>
                                <th class="text-center backBlack">{{$parent.lang.item_price}}</th>
                                <th class="text-center backGreen">{{$parent.lang.total_without_vat}}</th>
                                <th class="text-center backRed">{{$parent.lang.vat}}</th>
                                <th class="text-center backBlack">{{$parent.lang.ftotal}}</th>
                                <th class="text-center backBlack" v-if="cardid == 0">{{$parent.lang.action}}</th>
                            </tr>
                        </thead>
                        <tbody id="tablerow">
                            <tr v-for="(item,index) in tbrows1" :key="index">
                                <td v-if="$store.state.licenseType.cars && $store.state.licenseType.showCarPlate">{{ item.plate_number }}</td>
                                <td v-if="$store.state.licenseType.showItemCode">{{ item.itemcode }}</td>
                                <td><b-form-input v-model="tbrows1[index].item_name" /></td>
                                <td v-if="$store.state.licenseType.showExtra"><b-form-input v-model="tbrows1[index].extra1" /></td>
                                <td v-if="$store.state.licenseType.showExtra"><b-form-input v-model="tbrows1[index].extra2" /></td>
                                <td v-if="$store.state.licenseType.showExtra"><b-form-input v-model="tbrows1[index].extra3" /></td>
                                <td><b-form-input v-model="tbrows1[index].qty" @change="changeVatType()" /></td>
                                <td><b-form-input v-model="tbrows1[index].item_price" @change="changeVatType()" /></td>
                                <td class="text-center" style="background:lightgreen !important">{{ convertToComa(item.total) }}</td>
                                <td class="text-center" style="background:lightgreen !important">{{ convertToComa(item.vat) }}</td>
                                <td class="text-center">{{ convertToComa(item.ftotal) }}</td>
                                <td style="" v-if="cardid == 0">
                                    <b-button type="button" variant="danger" style="padding:0.15rem 0.5rem !important;font-weight:bolder;width:80px;background:red;border:none" @click="removeItem1(index)">حذف</b-button>
                                </td>
                            </tr>
                            <tr v-if="cardid == 0">
                                <td v-if="$store.state.licenseType.cars && $store.state.licenseType.showCarPlate" style="width:150px;">
                                    <b-input-group>
                                    <b-form-select
                                        class="selborder"
                                        style="background:red !important"
                                        :options="currentCars"
                                        v-model="newrow1.plate_number">
                                    </b-form-select>
                                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                        <i class="fas fa-arrow-down"></i>
                                        </b-input-group-append>
                                    </b-input-group>
                                </td>
                                <td style="width:150px;" v-if="$store.state.licenseType.showItemCode">
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow1.itemcode"
                                        @change="getProducts()"
                                        >
                                    </b-form-input>
                                </td>
                                <td>
                                    <b-form-input v-model="newrow1.item_name" list="items-list" @change="changeMe()"></b-form-input>
                                    <datalist id="items-list">
                                        <option v-for="(item,index) in spareparts" :key="index">{{item.item_code}} -||- {{ item[`item_name${$parent.lang.langname}`] }}</option>
                                    </datalist>
                                    <!-- <v-combobox
                                        v-model="newrow1.item_name"
                                        :items="spareparts"
                                        small-chips
                                        v-if="!$store.state.licenseType.requirePurchase"
                                        @change="getItemcode()"
                                    ></v-combobox>
                                    <span v-else>{{ newrow1.item_name }}</span> -->
                                </td>
                                <td v-if="$store.state.licenseType.showExtra">
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow1.extra1"
                                    ></b-form-input>
                                </td>
                                <td v-if="$store.state.licenseType.showExtra">
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow1.extra2"
                                    ></b-form-input>
                                </td>
                                <td v-if="$store.state.licenseType.showExtra">
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow1.extra3"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow1.qty"
                                        @change="calcKit1()"
                                        id="newitemqty"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow1.item_price"
                                        @change="calcKit1()"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow1.total"
                                        @change="calcKit1()"
                                        readonly
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow1.vat"
                                        @change="calcKit1()"
                                        readonly
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow1.ftotal"
                                        @change="calcKit1()"
                                        readonly
                                    ></b-form-input>
                                </td>
                                <td class="text-center" style="">
                                    <b-button type="button" class="btn-sm" variant="success" style="display:none;width:80px;font-weight:bolder;background:green;border:none;" @click="addNewRow1()">{{$parent.lang.add}}</b-button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <!-- <tr>
                                <th v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo" class="text-center backEmpty"></th>
                                <th class="text-center backEmpty" v-if="$store.state.licenseType.showItemCode"></th>
                                <th class="text-center backEmpty"></th>
                                <th v-if="$store.state.licenseType.showExtra" class="text-center backEmpty"></th>
                                <th v-if="$store.state.licenseType.showExtra" class="text-center backEmpty"></th>
                                <th v-if="$store.state.licenseType.showExtra" class="text-center backEmpty"></th>
                                <th class="text-center backEmpty"></th>
                                <td class="text-center backRed"> {{$parent.lang.total_discount}}</td>
                                <td class="text-center backRed">
                                    <b-form-input
                                        class="inborder"
                                        v-model="invoice.discount"
                                        @change="calcAll()"
                                    ></b-form-input>
                                </td>
                                <td class="text-center backEmpty"></td>
                                <td class="text-center backEmpty"></td>
                                <th class="text-center backEmpty"></th>
                            </tr> -->
                            <tr>
                                <th class="text-center backEmpty" v-if="$store.state.licenseType.cars && $store.state.licenseType.showCarPlate"></th>
                                <th class="text-center backEmpty" v-if="$store.state.licenseType.showItemCode"></th>
                                <th class="text-center backEmpty"></th>
                                <th class="text-center backEmpty"></th>
                                <th v-if="$store.state.licenseType.showExtra" class="text-center backEmpty"></th>
                                <th v-if="$store.state.licenseType.showExtra" class="text-center backEmpty"></th>
                                <th v-if="$store.state.licenseType.showExtra" class="text-center backEmpty"></th>
                                <th class="text-center backBlack">{{$parent.lang.total}}</th>
                                <th class="text-center backGreen">{{ convertToComa(sp.total) }}</th>
                                <th class="text-center backRed">{{ convertToComa(sp.vat) }}</th>
                                <th class="text-center backBlack">{{ convertToComa(sp.ftotal) }}</th>
                                <th class="text-center backEmpty" v-if="cardid == 0"></th>
                            </tr>
                            <!-- <tr>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"> تأمين</th>
                                <th class="text-center">
                                    <b-form-input
                                        class="inborder"
                                        v-model="invoice.insurance"
                                        @change="calcAll()"
                                        type="number"
                                    ></b-form-input>
                                </th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                            </tr>
                            <tr>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center">الاجمالي بعد التامين</th>
                                <th class="text-center">{{ full_total_in }}</th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                            </tr> -->
                            <!-- <tr v-if="invoice.invtype != 1 && full_total != 0">
                                <td class="backEmpty"></td>
                                <td class="backEmpty"></td>
                                <td class="backEmpty"></td>
                                <td class="backEmpty"></td>
                                <td v-if="$store.state.licenseType.showExtra" class="backEmpty"></td>
                                <td v-if="$store.state.licenseType.showExtra" class="backEmpty"></td>
                                <td v-if="$store.state.licenseType.showExtra" class="backEmpty"></td>
                                <td class="backEmpty" colspan="5" style="color:#000 !important;">
                                    <label>{{$parent.lang.payment_amount}}</label>
                                    <b-form-input
                                        class="inborder"
                                        :label="$parent.lang.payment_amount"
                                        v-model="invoice.payment.cash"
                                        style="background:#c3d9ff;width:150px"
                                        >{{ invoice.payment.cash }}</b-form-input>
                                </td>
                            </tr> -->
                        </tfoot>
                        
                    </template>
                </v-simple-table>
            </v-row>

            <v-row>
                <v-col cols="12" md="6" sm="8" xs="12">
                    <label>{{$parent.lang.add_invoice_notes}}</label>
                    <b-form-textarea
                        class="inborder"
                        :label="$parent.lang.add_invoice_notes"
                        v-model="invoice.notes"
                        >{{ invoice.notes }}</b-form-textarea>
                </v-col>
                <v-col class="text-center" cols="12" md="6" sm="12" xs="12" v-if="$store.state.licenseType.showSketch">
                    <div class="flexMe">
                        <div class="text-center" style="width:100px;margin:auto">
                            <v-btn style="width:100%;box-shadow:none;color:#fff;background:blue !important;border:1px solid #ccc !important;" @click.prevent="$refs.firstCar.$refs.VueCanvasDrawing.reset()">{{$parent.lang.reset}}</v-btn>
                            <v-btn style="width:100%;box-shadow:none;color:#fff;background:red !important;border:1px solid #ccc !important;" @click.prevent="$refs.firstCar.$refs.VueCanvasDrawing.undo()">{{$parent.lang.undo}}</v-btn>
                            <v-btn style="width:100%;box-shadow:none;color:#fff;background:green !important;border:1px solid #ccc !important;" @click="save()">{{$parent.lang.save}}</v-btn>
                        </div>
                        <div>
                            <div style="width:400px;margin:auto">
                                <b-input-group>
                                    <b-form-select class="selborder" :options="AcurrentCars" v-model="selectedCar" />
                                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                        <i class="fas fa-arrow-down"></i>
                                        </b-input-group-append>
                                </b-input-group>
                            </div>
                            <addCarScetch ref="firstCar" :CarsList="AcurrentCars" :carFirst="cars[0].plate_number" />
                        </div>
                        
                    </div>
                </v-col>
            </v-row>
            <div class="flexDis" style="margin-top:15px;">
                <div style="border:1px solid #000;width:33%;margin-inline-end:3px;" v-for="(item,index) in images" :key="index">
                    <div>
                        <img style="width:100%;" :src="item.image" />
                    </div>
                    <div style="border:1px solid #000;background:#000;color:#fff" class="text-center">{{ item.plate }}</div>
                </div>
            </div>
        </div>
        
    </template>
    
        <template #footer="" class="shadow">
        <div class="d-flex text-light align-items-center px-3 py-5">
          <b-button type="button" @click="addInvoicex()" v-if="$store.state.licenseType.showApprove" v-b-toggle.invoiceApprove id="addBTN" variant="success" class="ma-2 py-3 btn-sm" style="width:170px;font-size:1.3rem !important;">{{$parent.lang.save_n_print}}</b-button>
          <b-button type="button" @click="addInvoice()" v-else id="addBTN" variant="success" class="ma-2 py-3 btn-sm" style="width:170px;font-size:1.3rem !important;">{{$parent.lang.save_n_print}}</b-button>
          <b-button type="button" v-b-modal.add_addporch id="addModalBTN" variant="success" class="ma-2 py-3 btn-sm" style="display:none;width:170px;font-size:1.3rem !important;">{{$parent.lang.save_n_print}}</b-button>
        </div>
        
      </template>
    </b-sidebar>
    <invoiceAddApprove ref="approveInvoice" />
    <posAddPurhcase ref="posAddPurhcase" />
        
        </v-form>
      </div>
</template>

<script>
import axios from 'axios'
import invoiceAddApprove from '@/components/invoiceAddApprove.vue'
import {SnotifyPosition} from 'vue-snotify';
import posAddPurhcase from '@/components/pos-Purchase.vue'
import addCarScetch from '@/components/addCarScetch.vue'

export default{
    components: {invoiceAddApprove,posAddPurhcase,addCarScetch},
    props:[
        'custom','prods','_cardid'
    ],
    data() {
        return {
            quotID: 0,
            crmid: 0,
            allDesc: [],
            allProducts: [],
            images: [],
            counter: 1,
            showModal: false,
            showClientTypeRow: true,
            cardid: 0,
            payby: 1,
            itemsworkdes:[
                
            ],
            spareparts:[
                
            ],
            car: {
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
                vin: '',
                engine_meter: '',
                next_oil_change: '',
                brand: '',
            },
            cars: [{
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
                vin: '',
                engine_meter: '',
                next_oil_change: '',
                brand: '',
            }],
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            full_total: 0,
            full_vat: 0,
            full_ftotal: 0,
            showcamp: false,
            showGov: false,
            company:{
                fullname: '',
                vatid: '',
                projectname: '',
                building_no: '',
                street_name: '',
                branch_number: '',
                district: '',
                zipcode: '',
                city:'',
                address:'',
                crt_number: '',
                entity_name: '',
                branch_name: '',
                entity_branch_number: '',
                request_number: '',
                po_number: '',
                po_date: '',
            },
            customer: {
                id:0,
                mobile: '',
                full_name: '',
                customerid: '',
                cctype: 1,
                id_number: '',
                // ctype: [
                //     {
                //         text: this.$parent.lang.individual_client,
                //         value: 1,
                //     },
                //     {
                //         text: this.$parent.lang.company_client,
                //         value: 2,
                //     }
                // ]
            },
            invoice:{
                card_type: 1,
                old_invoice: 0,
                carsnumber: 1,
                owner_name: "",
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                payment:{
                    cash: '',
                    span: ''
                },
                billid: 1, 
                discount:0 ,
                t_discount:0 ,
                vtype:2,
                engine_meter: '',
                work_type: 1,
                next_oil_change: '',
                invtype:1,
                invtypes: [
                    {
                        text: this.$parent.lang.none_paid_invoice,
                        value: 2,
                    },
                    {
                        text: this.$parent.lang.paid_invoice,
                        value: 1,
                    },
                ],
                paytype: 2,
                paytypes: [
                    
                ],
                userid: 0,
                users: [

                ],
                notes: '',
                insurance: 0,
            },
            fieldRules: [
                v => !!v || this.$parent.lang.required_field,
            ],
            tbrows:[
            ],
            tbrows1:[
            ],
            newrow:{
                itemcode: '',
                item_type: 1,
                item_name: '',
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: '',
                plate_number: '',
                extra1: '',
                extra2: '',
                extra3: '',
                type: 'service',
            },
            newrow1:{
                item_type: 2,
                itemcode: '',
                item_name: '',
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: '',
                plate_number: '',
                extra1: '',
                extra2: '',
                extra3: '',
                type: 'sparepart',
            },
            allRows: [],
            customersList: [],
            calenderTypes: [],
            selectedCar: 0,
            attached: [],
        }
    },
    created() {
        this.getInvSettings();
        // setTimeout(() => {
        //     this.getInfo(); 
        // }, 2000);
        this.getPyamentType();
        // this.invoice.userid
        // this.invoice.userid = this.userinfo.userid
        // // console.log("select",this.$store.state.userinfo);

        this.changeAdduserInfo();
        this.getWorkType()
    },
    computed:{
        carsNums: () => {
            return [
                {text: '1',value: 1},
                {text: '2',value: 2},
                {text: '3',value: 3},
                {text: '4',value: 4},
                {text: '5',value: 5},
                {text: '6',value: 6},
            ]
        },
        sp: function(){
            let t = {
                total: 0,
                vat: 0,
                ftotal: 0
            }
            // console.log(this.tbrows1);
            for(let i=0;i<this.tbrows1.length;i++){
                t.total = parseFloat(t.total) + parseFloat(this.tbrows1[i].total);
                t.vat = parseFloat(t.vat) + parseFloat(this.tbrows1[i].vat);
                t.ftotal = parseFloat(t.ftotal) + parseFloat(this.tbrows1[i].ftotal);
            }
            return t;
        },
        nsp: function(){
            let t = {
                total: 0,
                vat: 0,
                ftotal: 0
            }
            // console.log(this.tbrows1);
            for(let i=0;i<this.tbrows.length;i++){
                t.total = parseFloat(t.total) + parseFloat(this.tbrows[i].total);
                t.vat = parseFloat(t.vat) + parseFloat(this.tbrows[i].vat);
                t.ftotal = parseFloat(t.ftotal) + parseFloat(this.tbrows[i].ftotal);
            }
            t.total = parseFloat(t.total) - parseFloat(this.invoice.discount);
            const v = this.$calcVat(t.total,this.invoice.vtype);
            t.vat = v.vat;
            t.ftotal = v.ftot
            t.total = v.tot
            return t;
        },
        customerLists: function(){

            let t = [{value: 0,text: this.$parent.lang.new_customer}];
            for(let i=0;i<this.customersList.length;i++){
                let customername = this.customersList[i].full_name;
                if(this.customer.cctype == 2){
                    customername = this.customersList[i].company_name;
                }
                else if(this.customer.cctype == 3){
                    customername = this.customersList[i].entity_name;
                }
                t.push({
                    text: customername, value: this.customersList[i].id
                })
            }
            return t;
        },
        full_total_in: function(){
            let t = 0;
            t = parseFloat(this.full_ftotal) - parseFloat(this.invoice.insurance);

            return this.$RoundNum(t);
        },
        currentCars: function(){
            let t = []
            for(let i=0;i<this.cars.length;i++){
                if(this.cars[i].plate_number != '')
                {
                    t.push({
                        text: this.cars[i].plate_number,
                        value: this.cars[i].plate_number,
                    })
                }
            }
            return t;
        },
        ecars: function(){
            let t = []
            for(let i=0;i<this.cars.length;i++){
                t.push(this.cars[i]);
            }
            return t;
        },
        workTypes: function(){
            let t = [];

            for(let i=0;i<this.calenderTypes.length;i++){
                t.push({
                    text: this.calenderTypes[i][`name_${this.$parent.lang.langname}`],value: this.calenderTypes[i].value
                })
            }
            // return [
            //     {text: 'سمكرة ودهان', value: 1},
            //     {text: 'دهان ', value: 2},
            //     {text: 'ميكانيكا وكهرباء', value: 3},
            //     {text: 'ميكانيكا', value: 4},
            //     {text: 'كهرباء', value: 5},
            //     {text: 'شفط وتعديل', value: 6},
            // ]
            return t;
        },
        AcurrentCars: function(){
            let t = [{text: this.$parent.lang.chose_car, value: 0}]
            for(let i=0;i<this.cars.length;i++){
                if(this.cars[i].plate_number != '')
                {
                    t.push({
                        text: this.cars[i].plate_number,
                        value: this.cars[i].plate_number,
                    })
                }
            }
            return t;
        },
        vattype: function(){
            if(this.$store.state.settings.vat_number == ''){
                return [
                    {
                        text: this.$parent.lang.prices_without_vat,
                        value: 1,
                    },
                ]
            }else{
                return [
                    {
                        text: this.$parent.lang.prices_include_vat,
                        value: 3,
                    },
                    {
                        text: this.$parent.lang.prices_exclusive_vat,
                        value: 2,
                    },
                    {
                        text: this.$parent.lang.prices_without_vat,
                        value: 1,
                    },
                ]
            }
            
        },
    },
    methods: {
        changeMe(){
            const itemn = this.newrow1.item_name.split(" -||- ");
            if(itemn.length > 1){
                this.newrow1.itemcode = itemn[0];
                this.getProducts();
            }
        },
        toUpper(index){
            this.cars[index].vin=this.cars[index].vin.toUpperCase()
        },
        getItemcode(){
            // console.log(this.allProducts);
            for(let i=0;i<this.allProducts.length;i++){
                if(this.allProducts[i].name == this.newrow1.item_name || this.allProducts[i].nameen == this.newrow1.item_name){
                    this.newrow1.itemcode = this.allProducts[i].itemcode
                }
            }
        },
        save(){
            if(this.$refs.firstCar.image == ''){
                let message = this.$parent.lang.plase_select_car_scratch;
                this.$snotify.error(message, this.$parent.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.selectedCar == '' || this.selectedCar == 0){
                let message = this.$parent.lang.chose_car_before_continue;
                this.$snotify.error(message, this.$parent.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            let test = false;
            for(let i =0;i < this.images.length;i++){
                if(this.selectedCar == this.images[i].plate){
                    test = true;
                    this.images[i].image = this.$refs.firstCar.image
                }
            }
            if(!test){
                this.images.push({
                    image: this.$refs.firstCar.image,
                    plate: this.selectedCar
                })
            }
            
            this.$refs.firstCar.$refs.VueCanvasDrawing.reset()
            this.$refs.firstCar.image = '';
            this.selectedCar = 0;
        },
        async getWorkType(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getStaticOptions')
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[phrase]','calendarTypes')
            const response = await axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            );
            if(response && response.data){
                this.calenderTypes = response.data.results.data
            }
        },
        updateCarsCount() {
            const n = parseFloat(this.invoice.carsnumber) - parseFloat(this.cars.length)
           
            if(n > 0) {
                for(let i = parseFloat(this.cars.length) - 1;i< (parseFloat(this.invoice.carsnumber) - parseFloat(1));i++){
                    // this.cars.push(this.car)
                    this.cars.push({
                        plate_number:  this.car.plate_number,
                        color:  this.car.color,
                        model:  this.car.model,
                        made_year:  this.car.made_year,
                        workdes:  this.car.workdes,
                        vin: this.car.vin,
                        engine_meter: this.car.engine_meter,
                        next_oil_change: this.car.next_oil_change,
                        brand: this.car.brand,
                    })
                }
            }else if(n < 0){
                // let d = parseFloat(this.cars.length) - parseFloat(this.invoice.carsnumber);
                // let i = 0;
                let ca = [];
                for(let i=0;i<this.cars.length;i++){
                    // alert([i,this.invoice.carsnumber]);
                    if(i < parseFloat(this.invoice.carsnumber)){
                        ca.push(this.cars[i])
                    }
                }
                this.cars = ca;
                // while (d != 0){
                //     // console.log(d,this.cars[i]);
                //     if(typeof this.cars[i] !== 'undefined'){
                //         if(this.cars[i].plate_number == ''){
                //             alert(i);
                //             this.cars.splice(i,1);
                //             d = d - 1;
                //         }
                //     }else{
                //         d = d - 1;
                //     }
                    
                //     i++;
                // }
                // if(d != 0){
                //     for(let i = this.cars.length - 1 ;i> d;i--){
                //         alert(d);
                //         this.cars.splice(i,1);
                //     }
                // }   
            }
        },
        getVatType(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getProducts");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[item_number]',this.newrow1.itemcode);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
//
            })
        },
        selectCustomer(){
            for(let i=0;i<this.customersList.length;i++){
                if(this.customer.cctype == 1 && this.customersList[i].id == this.customer.id){
                    this.customer.customerid = this.customersList[i].id;
                    this.customer.full_name = this.customersList[i].full_name
                    this.company.full_name = ''
                    this.company.entity_name = '';
                    this.company.branch_name = '';

                    this.company.fullname = '';
                    this.company.address = '';
                    this.company.building_no = '';
                    this.company.city = '';
                    this.company.company_vatid = '';
                    this.company.crt_number = '';
                    this.company.district = '';
                    this.company.branch_number = '';
                    this.company.street_name = '';
                    this.company.zipcode = '';
                }
                else if(this.customer.cctype == 2 && this.customersList[i].id == this.customer.id){
                    this.customer.customerid = this.customersList[i].customerid;
                    this.company.fullname = this.customersList[i].company_name;
                    this.company.address = this.customersList[i].address;
                    this.company.building_no = this.customersList[i].building_no;
                    this.company.city = this.customersList[i].city;
                    this.company.company_vatid = this.customersList[i].company_vatid;
                    this.company.crt_number = this.customersList[i].crt_number;
                    this.company.district = this.customersList[i].district;
                    this.company.branch_number = this.customersList[i].branch_number;
                    this.company.street_name = this.customersList[i].street_name;
                    this.company.zipcode = this.customersList[i].zipcode;
                }
                else if(this.customer.cctype == 3 && this.customersList[i].id == this.customer.id){
                    
                    this.company.customerid = this.customersList[i].id;
                    this.company.entity_name = this.customersList[i].entity_name;
                    this.company.branch_name = this.customersList[i].branch_name;

                    this.company.fullname = '';
                    this.company.address = '';
                    this.company.building_no = '';
                    this.company.city = '';
                    this.company.company_vatid = '';
                    this.company.crt_number = '';
                    this.company.district = '';
                    this.company.branch_number = '';
                    this.company.street_name = '';
                    this.company.zipcode = '';
                }
                if(this.customer.id == 0 || this.customersList[i].customer_type != this.customer.cctype){

                    this.customer.customerid = 0;
                    this.customer.id = 0
                    this.company.full_name = ''
                    this.company.entity_name = '';
                    this.company.branch_name = '';

                    this.company.fullname = '';
                    this.company.address = '';
                    this.company.building_no = '';
                    this.company.city = '';
                    this.company.company_vatid = '';
                    this.company.crt_number = '';
                    this.company.district = '';
                    this.company.branch_number = '';
                    this.company.street_name = '';
                    this.company.zipcode = '';
                }
            }
        },
        convertToComa(num){
            // // console.log(this.$store.state.licenseType.moneyComma);
            if(this.$store.state.licenseType.moneyComma){
                return Number(num).toLocaleString()
            }else{
                return num
            }
        },
        addInvoicex(){
            const date = new Date(this.invoice.date).getTime();
            const cdate = new Date().getTime();
            if(cdate < date){
                let message = "تاريخ الفاتورة يجب ان لا يتجاوز التاريخ الحالي تم اعادة التاريخ إلى تاريخ اليوم";
                this.invoice.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)
                this.$snotify.error(message, this.$parent.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                // return false;
            }
            this.allRows = [];
            for(let i=0;i<this.tbrows.length;i++){
                this.allRows.push(this.tbrows[i]);
            }
            for(let j=0;j<this.tbrows1.length;j++){
                this.allRows.push(this.tbrows1[j]);
            }
            for(let j=0;j<this.allRows.length;j++){
                if(this.allRows[j].item_price == ''){
                    let message = "يجب اضافة السعر كرقم في خانة السعر في الاصناف او اضافة صفر اذا كان بدون قيمة";
                    this.invoice.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)
                    this.$snotify.error(message, this.$parent.lang.alert, {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    return false;
                }
            }
            
            if(this.counter == 1){
                //
            }
            // if(this.$store.state.licenseType.directInvoice){
            //     this.addInvoice();
            //     return false;
            // }
            
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(!this.$refs.forinv.validate()){
                return false;
            }
            // if(this.customer.mobile == '') {
            //     this.customer.mobile = 1234567890;
            // }
            const mobilexx = this.customer.mobile.split("");
            if(mobilexx.length < 10 || mobilexx[0] != 0 || mobilexx[1] != 5){
                let message = "رقم اتصال العميل يجب ان يكون عشرة ارقام يبدأ بـ 05";
                this.$snotify.error(message, this.$parent.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.customer.full_name == '') {
                this.customer.full_name = this.$parent.lang.individual_client;
            }
            // // console.log("asdfasf",this.tbrows.length);
            // // console.log([this.full_ftotal,this.invoice.payment.cash]);
            let x = 1;
            const cash = parseFloat(this.full_ftotal) + parseFloat(this.sp.ftotal)
            if(this.invoice.payment.cash > cash){
                let message = "لا يمكن سداد مبلغ يتجاوز قيمة اجمالي الفاتورة";
                this.$snotify.error(message, this.$parent.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.invoice.payment.cash = this.full_ftotal
                if(this.invoice.payment.cash == 0) this.invoice.payment.cash = '';
                return false;
            }
            if(this.tbrows.length == 0 && this.tbrows1.length == 0){
                let message = this.$parent.lang.you_can_not_add_empty_invoice;
                this.$snotify.error(message, this.$parent.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            // building_no: '',
            // street_name: '',
            // branch_number: '',
            // district: '',
            // zipcode: '',
            // city:'',
            // address:'',
            // crt_number: ''
           let address = true;
           if(this.company.address == ''){
                if(
                    this.company.building_no == '' && this.company.street_name == '' &&
                    this.company.branch_number == '' && this.company.district == '' &&
                    this.company.zipcode == '' && this.company.city == ''
                ){
                    address = false;
                }
           }
        //    if(this.customer.cctype == 2 && (this.company.fullname == '' || !address || this.company.crt_number == '')){
           if(this.customer.cctype == 2 && (this.company.fullname == '' || !address)){
               let message = this.$parent.lang.company_ifnormation_required;
                this.$snotify.error(message, this.$parent.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
           }else if(this.customer.cctype == 2 && this.company.fullname != '' && address){
                this.customer.fullname = this.company.fullname;
                this.customer.full_name = this.company.fullname;
           }
           if(this.customer.cctype == 3 && this.company.entity_name == ''){
            let message = this.$parent.lang.company_ifnormation_required;
                this.$snotify.error(message, this.$parent.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
           }else if(this.customer.cctype == 3 && this.company.entity_name != ''){
                this.customer.fullname = this.company.entity_name;
                this.customer.full_name = this.company.entity_name;
           }
           if(this.$store.state.licenseType.carInfoRequired){
            for(let i=0;i<this.cars.length;i++){
                if(this.cars[i].plate_number == '' || this.cars[i].model == '' || this.cars[i].made_year == ''){
                    let message = this.$parent.lang.car_ifnormation_required;
                    this.$snotify.error(message, this.$parent.lang.alert, {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    // return false;
                }
            }
           }
           this.invoice.payment.cash = parseFloat(this.invoice.payment.cash) + parseFloat(this.sp.ftotal)
            this.$refs.approveInvoice.modifyIt({
                total: parseFloat(this.nsp.total) + parseFloat(this.sp.total),
                vat: parseFloat(this.nsp.vat) + parseFloat(this.sp.vat),
                ftotal: parseFloat(this.nsp.ftotal) + parseFloat(this.sp.ftotal),
                paid: this.invoice.payment.cash,
                work_type: this.invoice.work_type,
                trow: this.allRows
            })
        },
        reset(){
            this.images = []
            this.tbrows = [];
            this.tbrows1 = [];
            this.carsnumber = 1;
            this.cars = [{
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
                vin: '',
                engine_meter: '',
                next_oil_change: '',
                brand: '',                
            }]
            this.company = {
                fullname: '',
                vatid: '',
                projectname: '',
                building_no: '',
                street_name: '',
                branch_number: '',
                district: '',
                zipcode: '',
                city:'',
                address:'',
                crt_number: '',
                entity_name: '',
                branch_name: '',
                entity_branch_number: '',
                request_number: '',
                po_number: '',
                po_date: '',
            }
            this.customer.id = 0;
            this.customer.mobile = '';
            this.customer.full_name = '';
            this.customer.customerid = '';
            this.customer.cctype = '';

            this.invoice.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.invoice.billid = 1
            this.invoice.discount = 0
            this.invoice.t_discount = 0
            this.invoice.engine_meter = ''
            this.invoice.next_oil_change = ''
            this.invoice.notes = ''
            this.invoice.insurance = ''
            this.newrow.itemcode = '';
            this.newrow.item_name = '';
            this.newrow.qty = 1;
            this.newrow.newrow = 1;
            this.resetInvoice();
        },
        updateCars(car){
            let cars = this.cars;
            for(let i=0;i<cars.length;i++){
                if(cars[i].plate_numebr == car.plate_numebr){
                    cars[i] = car;
                }
            }
            this.cars = cars;
        },
        postNewRow(){
            for(let i=0;i<this.currentCars.length;i++){
                this.newrow.plate_number=this.newrow[i].plate_number;
            }
        },
        deleteCar(index){
            this.cars.splice(index,1);
        },
        addNewCar(){
            this.cars.push({
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
                vin: '',
                engine_meter: '',
                next_oil_change: '',
                brand: ''
            });
        },
        changeCusType(id){
            
            if(id == 0){
                this.customer.cctype = 1;
                this.showcamp = false;
                this.showGov = false;
                document.getElementById('_indiv').classList.remove('hideme');
                document.getElementById('_comps').classList.add('hideme');
                document.getElementById('_goves').classList.add('hideme');

                document.getElementById('_cInd').classList.remove('redColor');
                document.getElementById('_cCom').classList.remove('redColor');
                document.getElementById('_cGov').classList.remove('redColor');
                document.getElementById('_cInd').classList.remove('weightColor');
                document.getElementById('_cCom').classList.remove('weightColor');
                document.getElementById('_cGov').classList.remove('weightColor');

                document.getElementById('_cInd').classList.add('redColor');
                document.getElementById('_cCom').classList.add('weightColor');
                document.getElementById('_cGov').classList.add('weightColor');

            }else if(id == 1){
                this.customer.cctype = 2;
                this.showcamp = true;
                this.showGov = false;
                document.getElementById('_comps').classList.remove('hideme');
                document.getElementById('_indiv').classList.add('hideme');
                document.getElementById('_goves').classList.add('hideme');

                document.getElementById('_cInd').classList.remove('redColor');
                document.getElementById('_cCom').classList.remove('redColor');
                document.getElementById('_cGov').classList.remove('redColor');
                document.getElementById('_cInd').classList.remove('weightColor');
                document.getElementById('_cCom').classList.remove('weightColor');
                document.getElementById('_cGov').classList.remove('weightColor');

                document.getElementById('_cInd').classList.add('weightColor');
                document.getElementById('_cCom').classList.add('redColor');
                document.getElementById('_cGov').classList.add('weightColor');

            }
            else if(id == 2){
                this.customer.cctype = 3;
                this.showGov = true;
                this.showcamp = false;
                document.getElementById('_goves').classList.remove('hideme');
                document.getElementById('_comps').classList.add('hideme');
                document.getElementById('_indiv').classList.add('hideme');

                document.getElementById('_cInd').classList.remove('redColor');
                document.getElementById('_cCom').classList.remove('redColor');
                document.getElementById('_cGov').classList.remove('redColor');
                document.getElementById('_cInd').classList.remove('weightColor');
                document.getElementById('_cCom').classList.remove('weightColor');
                document.getElementById('_cGov').classList.remove('weightColor');

                document.getElementById('_cInd').classList.add('weightColor');
                document.getElementById('_cCom').classList.add('weightColor');
                document.getElementById('_cGov').classList.add('redColor');

            }
            this.customersList = [];
            this.getAllName();
            // this.changeCtype();
        },
        changeAdduserInfo(){
            const cook = this.$cookies.get(this.$store.state.COOKIEPhase).split('||');
            this.invoice.userid = cook[0]
        },
        getProducts() {
            // if(this.newrow1.itemcode == ''){
            //     return false;
            // }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getProducts");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[item_number]',this.newrow1.itemcode == '' ? this.newrow.itemcode : this.newrow1.itemcode);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                
                if(response.data.results.data.length > 0 || !this.$store.state.licenseType.requirePurchase){
                    
                    if(response.data.results.data[0].qty > 0 || !this.$store.state.licenseType.require_itemcode || !this.$store.state.licenseType.requirePurchase){
                        this.newrow1.itemcode = response.data.results.data[0].item_code;
                        this.newrow1.item_name = response.data.results.data[0][`item_name${this.$parent.lang.langname}`];
                        this.newrow1.item_price = response.data.results.data[0].default_price;
                        this.newrow1.qty = 1;
                        // this.calcKit();
                        this.calcKit1();
                        document.getElementById('newitemqty').focus();
                    }else{
                        this.newrow1.itemcode = '';
                        this.newrow1.description = '';
                        this.newrow1.qty = '';
                        this.newrow1.price = '';
                        this.newrow1.total = '';
                        
                        let message = this.$parent.lang.error_no_qty;
                        this.$snotify.error(message, this.$parent.lang.alert, {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.$parent.lang.cancel, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                        // document.getElementById('addModalBTN').click()
                                    } 
                                },
                                {
                                    text: this.parent.purchase, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                        document.getElementById('addModalBTN').click()
                                    } 
                                },
                            ]
                        });
                    }
                    
                }else{
                    this.newrow.itemcode = '';
                    this.newrow.description = '';
                    this.newrow.qty = '';
                    this.newrow.price = '';
                    this.newrow.total = '';
                    let message = this.$parent.lang.error_no_qty;
                        this.$snotify.error(message, this.$parent.lang.alert, {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.$parent.lang.cancel, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                        // document.getElementById('addModalBTN').click()
                                    } 
                                },
                                {
                                    text: this.$parent.lang.purchase, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                        document.getElementById('addModalBTN').click()
                                    } 
                                },
                            ]
                        });
                }
                this.calcKit();
                this.calcKit1();
            })
        },
        getItemname(){
            // if(this.newrow.itemcode == ''){
            //     return false;
            // }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "getItemBycode"); 
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[itemcode]',this.newrow.itemcode)
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data.results.data.results;
                if(res.length != 0){
                    this.newrow.item_name = res[0].name;
                    this.newrow.item_price = res[0].price;
                }
                this.calcKit();
                this.calcKit1();
                document.getElementById('newitemqty').focus();
            })
        },
        getInfo(){
            if(this.custom == null) {return false;}
            this.customer.mobile = this.custom.mobile;
            this.company.fullname = this.custom.company;
            this.company.vatid = this.custom.vatid;
            this.getName();
        },
        resetInvoice(){
            this.payby = 1;
            this.car = {
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
            };
            this.modal = false;
            this.full_total =  0;
            this.full_vat =  0;
            this.full_ftotal =  0;
            this.showcamp =  false;
            this.company = {
                fullname: '',
                vatid: '',
                address: '',
            };
            this.customer.id = 0;
            this.customer.mobile = '';
            this.customer.full_name = '';
            this.customer.customerid = '';
            this.customer.cctype = 1;
            this.customer.id_number = "";
            this.invoice.payment.cash = '';
            this.invoice.payment.span = '';
            this.invoice.payment.discount = 0;
            this.invoice.payment.vtype = 0;
            this.invoice.payment.t_discount = 0;
            this.invoice.owner_name = 0;
            this.changeCusType(0)
            this.tbrows = [
            ];
            this.newrow = {
                plate_number: this.newrow.plate_numebr,
                itemcode: '',
                item_type: 1,
                item_name: '',
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: '',
                extra1: '',
                extra2: '',
                extra3: '',
                type: 'service',
            }
        },
        addInvoice(){
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(!this.$refs.forinv.validate()){
                return false;
            }
            if(this.customer.mobile == '') {
                this.customer.id = 1;
            }
            if(this.customer.full_name == '') {
                this.customer.id = 1;
            }
            // // console.log("asdfasf",this.tbrows.length);
            // // console.log([this.full_ftotal,this.invoice.payment.cash]);
            let x = 1;
            const cash = parseFloat(this.full_ftotal) + parseFloat(this.sp.ftotal)
            if(this.invoice.payment.cash > cash){
                let message = "لا يمكن سداد مبلغ يتجاوز قيمة اجمالي الفاتورة";
                this.$snotify.error(message, this.$parent.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.invoice.payment.cash = this.full_ftotal
                if(this.invoice.payment.cash == 0) this.invoice.payment.cash = '';
                return false;
            }
            for(let i = 0; i < this.tbrows.length;i++){
                // console.log(this.tbrows);
                if(this.tbrows[i].item_price == ''){
                    let message = "لا يمكن اضافة سعر فارغ يرجى اضافة السعر او رقم صفر ";
                    this.$snotify.error(message, this.$parent.lang.alert, {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    this.invoice.payment.cash = this.full_ftotal
                    if(this.invoice.payment.cash == 0) this.invoice.payment.cash = '';
                    return false;
                }
            }
            for(let i = 0; i < this.tbrows1.length;i++){
                // console.log(this.tbrows1);
                if(this.tbrows1[i].item_price == ''){
                    let message = "لا يمكن اضافة سعر فارغ يرجى اضافة السعر او رقم صفر ";
                    this.$snotify.error(message, this.$parent.lang.alert, {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    this.invoice.payment.cash = this.full_ftotal
                    if(this.invoice.payment.cash == 0) this.invoice.payment.cash = '';
                    return false;
                }
            }
            if(this.tbrows.length == 0 && this.tbrows1.length == 0){
                let message = this.$parent.lang.you_can_not_add_empty_invoice;
                this.$snotify.error(message, this.$parent.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            // building_no: '',
            // street_name: '',
            // branch_number: '',
            // district: '',
            // zipcode: '',
            // city:'',
            // address:'',
            // crt_number: ''
           let address = true;
           if(this.company.address == ''){
                if(
                    this.company.building_no == '' && this.company.street_name == '' &&
                    this.company.branch_number == '' && this.company.district == '' &&
                    this.company.zipcode == '' && this.company.city == ''
                ){
                    address = false;
                }
           }
        //    if(this.customer.cctype == 2 && (this.company.fullname == '' || !address || this.company.crt_number == '')){
           if(this.customer.cctype == 2 && (this.company.fullname == '' || !address)){
               let message = this.$parent.lang.company_ifnormation_required;
                this.$snotify.error(message, this.$parent.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
           }else if(this.customer.cctype == 2 && this.company.fullname != '' && address){
                this.customer.fullname = this.company.fullname;
                this.customer.full_name = this.company.fullname;
           }
           if(this.customer.cctype == 3 && this.company.entity_name == ''){
            let message = this.$parent.lang.company_ifnormation_required;
                this.$snotify.error(message, this.$parent.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
           }else if(this.customer.cctype == 3 && this.company.entity_name != ''){
                this.customer.fullname = this.company.entity_name;
                this.customer.full_name = this.company.entity_name;
           }
           if(this.$store.state.licenseType.carInfoRequired){
             for(let i=0;i<this.cars.length;i++){
                if(this.cars[i].plate_number == '' || this.cars[i].model == '' || this.cars[i].made_year == ''){
                    let message = this.$parent.lang.car_ifnormation_required;
                    this.$snotify.error(message, this.$parent.lang.alert, {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    // return false;
                }
             }
           }
            document.getElementById('addBTN').disabled = true;
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "addInvoice"); 
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            for(const [key, value] of Object.entries(this.customer)){
                post.append('data[customer]['+key+']]',value);
            }
            // // console.log(this.company);
            for(const [key, value] of Object.entries(this.company)){
                post.append('data[company]['+key+']]',value);
            }
            for(let i=0;i<this.images.length;i++){
                post.append('data[images]['+i+'][image]',this.images[i].image)
                post.append('data[images]['+i+'][plate]',this.images[i].plate)
            }
            let rows = [];
            for(let i = 0; i < this.tbrows.length;i++){
                rows.push(this.tbrows[i])
            }
            for(let i = 0; i < this.tbrows1.length;i++){
                rows.push(this.tbrows1[i])
            }

            for(let i = 0; i < rows.length;i++){
                for(const [key, value] of Object.entries(rows[i])){
                    post.append('data[tbrows]['+i+']['+key+']]',value);
                }
            }
            // for(let i = 0; i < this.tbrows1.length;i++){
            //     for(const [key, value] of Object.entries(this.tbrows1[i])){
            //         post.append('data[tbrows]['+i+']['+key+']]',value);
            //     }
            // }
            for(let i = 0; i < this.cars.length;i++){
                for(const [key, value] of Object.entries(this.cars[i])){
                    post.append('data[cars]['+i+']['+key+']]',value);
                }
            }
            // for(const [key, value] of Object.entries(this.car)){
            //     post.append('data[car]['+key+']]',value);
            // }
            // for(let i = 0; i < this.inovice.length;i++){
            //     for(const [key, value] of Object.entries(this.inovice[i])){
            //         post.append('data[inovice]['+i+']['+key+']]',value);
            //     }
            // }
            if(this.invoice.invtype == 1){
                this.invoice.payment.cash = parseFloat(this.nsp.ftotal) + parseFloat(this.sp.ftotal);
            }
            post.append("data[inovice][date]",this.invoice.date);
            post.append("data[inovice][itemcode]",0);
            post.append("data[inovice][payment][cash]",this.invoice.payment.cash);
            post.append("data[inovice][payment][span]",this.invoice.payment.span);
            post.append("data[inovice][billid]",this.invoice.billid);
            post.append("data[inovice][vtype]",this.invoice.vtype);
            post.append("data[inovice][work_type]",this.invoice.work_type);
            post.append("data[inovice][invtype]",this.invoice.invtype);
            post.append("data[inovice][paytype]",this.invoice.paytype);
            post.append("data[inovice][userid]",this.invoice.userid);
            post.append("data[inovice][full_total]",parseFloat(this.nsp.total) + parseFloat(this.sp.total));
            post.append("data[inovice][full_vat]",parseFloat(this.nsp.vat) + parseFloat(this.sp.vat));
            post.append("data[inovice][full_ftotal]",parseFloat(this.nsp.ftotal) + parseFloat(this.sp.ftotal));
            post.append("data[inovice][t_discount]",this.invoice.discount);
            post.append("data[inovice][invtype]",this.invoice.invtype);
            post.append("data[inovice][cash]",this.invoice.payment.cash == '' ? 0 : this.invoice.payment.cash);
            post.append("data[inovice][span]",this.invoice.payment.span);
            post.append("data[inovice][notes]",this.invoice.notes);
            post.append("data[inovice][insurance]",this.invoice.insurance);
            post.append("data[inovice][owner_name]",this.invoice.owner_name);
            post.append("data[inovice][projectname]",this.company.projectname);
            post.append("data[inovice][card_type]",this.company.card_type );
            post.append("data[inovice][old_invoice]",this.company.old_invoice );
            // post.append("data[inovice][engine_meter]",this.invoice.engine_meter);
            // post.append("data[inovice][next_oil_change]",this.invoice.next_oil_change);
            post.append("data[cardid]",this.cardid);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((resposne) => { 
                // // console.log("addinvoice",resposne.data);
                document.getElementById('addBTN').disabled = false;
                if (this.$snotify.notifications.length > 0) {
                    this.$snotify.notifications.forEach(notification => {
                        this.$snotify.remove(notification.id)
                    });
                }
                let message = this.$parent.lang.invoice_added;
                this.$snotify.error(message, this.$parent.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.printPDF(resposne.data.results.data.results.newID);
                document.getElementById('HideInvoice').click();
                this.resetInvoice();
                // console.log(this.showClientTypeRow);
                if(this.showClientTypeRow){
                    if(this.cardid && this.cardid != 0){
                        this.$parent.getCard(this.cardid);
                        this.$parent.$parent.getCurrentCards();
                    }
                    else{
                        this.$parent.getCurrentCards();
                    }
                }else{
                    this.$parent.getCustomerInfo();
                }
                
                this.$parent.getCustomerInfo();
                
            })
        },
        printInvo(id){
            window.open('../api/print.php?invid='+id,'_blank');
        },
        printPDF (id)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print.php?invid='+id;
        },
        changePtype(){
            // let tbs = [];
            //  if(this.tbrows.length != 0){
            //     for(let i = 0; i < this.tbrows.length; i++){
            //         let fftotal = parseFloat(this.tbrows[i].item_price) * parseFloat(this.tbrows[i].qty);
            //         fftotal = this.$RoundNum(fftotal);
            //         const vs = this.$calcVat(fftotal,this.invoice.vtype);
            //         const itm = {
            //             item_name: this.tbrows[i].item_name,
            //             qty: this.tbrows[i].qty,
            //             item_price: this.tbrows[i].item_price,
            //             total: vs.tot,
            //             vat: vs.vat,
            //             ftotal: vs.ftot
            //         }
            //         tbs.push(itm);
            //     }
            // }
            // this.tbrows = tbs;
            // this.calcAll();
            // this.calcKit();
        },
        recalcTbrows(){
            
            for(let i=0;i < this.tbrows.length;i++){
                const vt = this.invoice.vtype;
                const price = +this.tbrows[i].item_price * +this.tbrows[i].qty;
                const vat = this.$calcVat(price,vt);
                this.tbrows[i].total = this.$RoundNum(vat.tot);
                this.tbrows[i].ftotal = this.$RoundNum(vat.ftot);
                this.tbrows[i].vat = this.$RoundNum(vat.vat);
            }
            for(let i=0;i < this.tbrows1.length;i++){
                const vt1 = this.invoice.vtype;
                const price1 = +this.tbrows1[i].item_price * +this.tbrows1[i].qty;
                const vat1 = this.$calcVat(price1,vt1);
                this.tbrows1[i].total = this.$RoundNum(vat1.tot);
                this.tbrows1[i].ftotal = this.$RoundNum(vat1.ftot);
                this.tbrows1[i].vat = this.$RoundNum(vat1.vat);
            }
        },
        changeVatType(){
            this.recalcTbrows();
            this.calcKit();
            this.calcKit1();
            this.calcAll();
        },
        calcAll(){
            // this.full_total = 0;
            // this.full_vat = 0;
            // this.full_ftotal = 0;
            // if(this.tbrows.length != 0){
            //     for(let i = 0; i < this.tbrows.length; i++){
            //         this.full_total = parseFloat(this.nsp.total) + parseFloat(this.tbrows[i].total);
            //         this.full_vat = parseFloat(this.nsp.ftotal) + parseFloat(this.tbrows[i].vat);
            //         this.full_ftotal = parseFloat(this.nsp.vat) + parseFloat(this.tbrows[i].ftotal);
            //     }
            // }
            // if(this.spareparts.length != 0){
            //     for(let i = 0; i < this.spareparts.length; i++){
            //         this.full_total = parseFloat(this.full_total) + parseFloat(this.spareparts[i].total);
            //         this.full_vat = parseFloat(this.full_vat) + parseFloat(this.spareparts[i].vat);
            //         this.full_ftotal = parseFloat(this.full_ftotal) + parseFloat(this.spareparts[i].ftotal);
            //     }
            // }
            // let discount = this.invoice.discount;
            // if(discount == '') discount = 0;
            // if(this.$store.state.licenseType.perDiscount){
            //     if(this.invoice.discount > 30) this.invoice.discount = 30;
            //     discount = parseFloat(this.invoice.discount) * parseFloat(this.full_total) / parseFloat(100);
            // }
            // if(discount > this.full_ftotal/2){
            //     discount = this.full_ftotal/2;
            // }
            // // alert(discount);
            // if(discount != 0){
            //     // this.invoice.t_discount = parseFloat(this.invoice.discount) * parseFloat(this.full_total) / parseFloat(100);
            //     this.invoice.t_discount = parseFloat(discount);
            //     this.full_total = parseFloat(this.full_total) - this.invoice.t_discount;
            //     //* mohammed
            //     const vatic = this.$calcVat(this.full_total,this.invoice.vtype)
            //     this.full_vat = vatic.vat;
            //     this.full_ftotal = vatic.ftot;
            // }
            // this.full_total = this.$RoundNum(this.full_total); 
            // this.full_vat = this.$RoundNum(this.full_vat);
            // this.full_ftotal = this.$RoundNum(this.full_ftotal);
            // if(this.invoice.paytype == 1 || this.invoice.paytype == 3){
            //     this.invoice.payment.cash = this.full_ftotal;
            //     this.invoice.payment.span = 0;
            // }else if(this.invoice.paytype == 2){
            //     this.invoice.payment.cash = '';
            //     this.invoice.payment.span = this.full_ftotal;
            // }
            // if(this.invoice.invtype == 2){
            //     this.invoice.payment.cash = '';
            //     this.invoice.payment.span = 0;
            // }
        },
        InvType () {
            if(this.invoice.invtype == 1){
                if(this.paytype == 1){
                    this.invoice.payment.cash = this.full_ftotal;
                    this.payment.span = 0;
                }else{
                    this.invoice.payment.span = this.full_ftotal;
                    this.invoice.payment.cash = '';
                }
            }else{
                this.invoice.payment.span = 0;
                this.invoice.payment.cash = '';
            }
        },
        removeItem(item){
            this.tbrows.splice(item, 1);
            this.calcAll()
        },
        removeItem1(item){
            this.tbrows1.splice(item, 1);
            this.calcAll()
        },
        calcKit() {
            const vt = this.invoice.vtype;
            const price = +this.newrow.item_price * +this.newrow.qty;
            const vat = this.$calcVat(price,vt);
            this.newrow.total = this.$RoundNum(vat.tot);
            this.newrow.ftotal = this.$RoundNum(vat.ftot);
            this.newrow.vat = this.$RoundNum(vat.vat);
            if(this.price != ''){
                this.addNewRow();
            }
        },
        calcKit1() {
            const vt = this.invoice.vtype;
            const price = +this.newrow1.item_price * +this.newrow1.qty;
            const vat = this.$calcVat(price,vt);
            this.newrow1.total = this.$RoundNum(vat.tot);
            this.newrow1.ftotal = this.$RoundNum(vat.ftot);
            this.newrow1.vat = this.$RoundNum(vat.vat);
            if(this.price != ''){
                this.addNewRow1();
            }
        },
        addNewRow() {
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(this.newrow.item_name == ''){
                // let message = this.$parent.lang.can_not_add_item_withoud_discription;
                // this.$snotify.error(message, this.$parent.lang.alert, {
                //     timeout: 1000000,
                //     showProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     position: SnotifyPosition.centerCenter,
                //     buttons: [
                //         {
                //             text: this.$parent.lang.close, 
                //             action: (toast) => {
                //                 this.$snotify.remove(toast.id); 
                //             } 
                //         },
                //     ]
                // });
                return false;
            }
            if(this.newrow.qty == '' || this.newrow.qty == 0){
                // let message = this.$parent.lang.item_qtty_must_be_more_than_zero;
                // this.$snotify.error(message, this.$parent.lang.alert, {
                //     timeout: 1000000,
                //     showProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     position: SnotifyPosition.centerCenter,
                //     buttons: [
                //         {
                //             text: this.$parent.lang.close, 
                //             action: (toast) => {
                //                 this.$snotify.remove(toast.id); 
                //             } 
                //         },
                //     ]
                // });
                return false;
            }
            if(!this.$store.state.zeroPrice){

                if(this.newrow.item_price == '' || this.newrow.item_price == 0){
                    // let message = this.$parent.lang.item_price_must_be_more_than_zero;
                    // this.$snotify.error(message, this.$parent.lang.alert, {
                    //     timeout: 1000000,
                    //     showProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     position: SnotifyPosition.centerCenter,
                    //     buttons: [
                    //         {
                    //             text: this.$parent.lang.close, 
                    //             action: (toast) => {
                    //                 this.$snotify.remove(toast.id); 
                    //             } 
                    //         },
                    //     ]
                    // });
                    
                    return false;
                }
            }
            this.tbrows.push(this.newrow);
            this.newrow = {
                itemcode: '',
                item_type: 1,
                item_name: '',
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: '',
                extra1: '',
                extra2: '',
                extra3: '',
                type: 'service',
                plate_number: this.newrow.plate_number,
            }
            this.calcAll()
        },
        addNewRow1() {
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(this.newrow1.item_name == ''){
                // let message = this.$parent.lang.can_not_add_item_withoud_discription;
                // this.$snotify.error(message, this.$parent.lang.alert, {
                //     timeout: 1000000,
                //     showProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     position: SnotifyPosition.centerCenter,
                //     buttons: [
                //         {
                //             text: this.$parent.lang.close, 
                //             action: (toast) => {
                //                 this.$snotify.remove(toast.id); 
                //             } 
                //         },
                //     ]
                // });
                return false;
            }
            if(this.newrow1.qty == '' || this.newrow1.qty == 0){
                // let message = this.$parent.lang.item_qtty_must_be_more_than_zero;
                // this.$snotify.error(message, this.$parent.lang.alert, {
                //     timeout: 1000000,
                //     showProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     position: SnotifyPosition.centerCenter,
                //     buttons: [
                //         {
                //             text: this.$parent.lang.close, 
                //             action: (toast) => {
                //                 this.$snotify.remove(toast.id); 
                //             } 
                //         },
                //     ]
                // });
                return false;
            }
            if(!this.$store.state.zeroPrice){

                if(this.newrow1.item_price == '' || this.newrow1.item_price == 0){
                    // let message = this.$parent.lang.item_price_must_be_more_than_zero;
                    // this.$snotify.error(message, this.$parent.lang.alert, {
                    //     timeout: 1000000,
                    //     showProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     position: SnotifyPosition.centerCenter,
                    //     buttons: [
                    //         {
                    //             text: this.$parent.lang.close, 
                    //             action: (toast) => {
                    //                 this.$snotify.remove(toast.id); 
                    //             } 
                    //         },
                    //     ]
                    // });
                    
                    return false;
                }
            }
            this.tbrows1.push(this.newrow1);
            this.newrow1 = {
                itemcode: '',
                item_name: '',
                qty: 1,
                item_price: '',
                total: 0,
                vat: 0,
                ftotal: 0,
                plate_number: this.newrow.plate_number,
                type: 'sparepart',
            }
            this.calcAll()
        },
        changeCtype() {
            if(this.customer.cctype == 2){
                this.showcamp = true;
            }else{
                this.showcamp = false;
            }
        },
        getInvSettings() {
            if(this.$store.state.settings.vat_number == ''){
                this.invoice.vtype = 1;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getInvSettings');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[all]','all');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res != '' && res.error.number == 200){
                        if(res.results.data.users.length != 0){
                            for(let i= 0; i < res.results.data.users.length; i++){
                                this.invoice.users.push({
                                    text: res.results.data.users[i].full_name,
                                    value: res.results.data.users[i].id
                                })
                            }
                        }
                        if(res.results.data.desk.length != 0){
                            this.itemsworkdes = [];
                            this.allDesc = res.results.data.desk;
                            for(let i= 0; i < res.results.data.desk.length; i++){
                                this.itemsworkdes.push(res.results.data.desk[i].title);
                            }
                        }
                        if(res.results.data.products.length != 0){
                            this.spareparts = [];
                            this.allProducts = res.results.data.products;
                            this.spareparts = res.results.data.products
                            // for(let i= 0; i < res.results.data.products.length; i++){
                            //     this.spareparts.push(res.results.data.products[i].name);
                            // }
                        }
                    }
                }
            )
        },
        getAllName(id){
            // this.customer.mobile = "";
            if(this.customer.customerid != ''){
                return false;
            }
            this.customer.customerid = "";
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','customerInfoAll');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[mobile]',this.customer.mobile);
            post.append('data[customerid]',this.customer.customerid);
            post.append('data[customer_type]',this.customer.cctype);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    this.customersList = res.results.data;
                    
                    // if(this.customer.customerid != ''){
                        this.company = {
                            fullname: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].company_name : '',
                            vatid: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].company_vatid : '',
                            projectname: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].projectname : '',
                            building_no: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].building_no : '',
                            street_name: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].street_name : '',
                            branch_number: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].branch_number : '',
                            district: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].district : '',
                            zipcode: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].zipcode : '',
                            city: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].city : '',
                            address: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].address : '',
                            crt_number: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].crt_number : '',
                            entity_name: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].entity_name : '',
                            branch_name: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].branch_name : '',
                            entity_branch_number: '',
                            request_number: "",
                            po_number: "",
                            po_date: "",
                        }
                        this.customer = {
                            id: res.results.data[0].id,
                            customerid: res.results.data[0].id,
                            mobile: res.results.data[0].mobile,
                            full_name: res.results.data[0].full_name,
                            cctype: res.results.data[0].customer_type
                        }
                        this.changeCusType(parseFloat(res.results.data[0].customer_type) - 1)
                    // }
                }
            )
        },
        getAllNameById(id){
            this.customer.mobile = "";
            // this.customer.customerid = "";
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','customerInfoAll');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[mobile]',this.customer.mobile ? this.customer.mobile : '');
            post.append('data[customerid]',this.customer.customerid);
            post.append('data[customer_type]',this.customer.cctype);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    this.customersList = res.results.data;
                    
                    // if(this.customer.customerid != ''){
                        this.company = {
                            fullname: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].company_name : '',
                            vatid: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].company_vatid : '',
                            projectname: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].projectname : '',
                            building_no: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].building_no : '',
                            street_name: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].street_name : '',
                            branch_number: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].branch_number : '',
                            district: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].district : '',
                            zipcode: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].zipcode : '',
                            city: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].city : '',
                            address: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].address : '',
                            crt_number: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].crt_number : '',
                            entity_name: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].entity_name : '',
                            branch_name: typeof res.results.data[0] !== 'undefined' ? res.results.data[0].branch_name : '',
                            entity_branch_number: '',
                            request_number: "",
                            po_number: "",
                            po_date: "",
                        }
                        this.customer = {
                            id: res.results.data[0].id,
                            customerid: res.results.data[0].id,
                            mobile: res.results.data[0].mobile,
                            full_name: res.results.data[0].full_name,
                            cctype: res.results.data[0].customer_type
                        }
                        this.changeCusType(parseFloat(res.results.data[0].customer_type) - 1)
                    // }
                }
            )
        },
        getName(id){
            this.customersList = [];
            this.getAllName();
            // if(id == 0){
            //     this.customer.mobile = '';
            // }else{
            //     this.customer.customerid = "";
            // }
            // const post = new FormData();
        //     post.append('defBranch',localStorage.getItem('defBranch'));
        //   post.append('financeYear',localStorage.getItem('financeYear'));
            // post.append('type','customerInfo');
            // post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            // post.append('data[mobile]',this.customer.mobile);
            // post.append('data[customerid]',this.customer.customerid);
            // axios.post(
            //     this.$SAMCOTEC.r_path, post
            // ).then(
            //     (response) => {
            //         const res = response.data;
                    
            //         if(res.error.number == 200){
            //             this.customer.id = res.results.data['id'];
            //             this.customer.mobile = res.results.data['mobile'];
            //             this.customer.full_name = res.results.data['full_name'];
            //             this.customer.customerid = res.results.data['customerid'];
            //             this.company.fullname = res.results.data['company_name'];
            //             this.company.vatid = res.results.data['company_vatid'];
            //             this.company.crt_number = res.results.data['crt_number'];
            //             this.company.address = res.results.data['address'];
            //             this.company.city = res.results.data['city'];
            //             this.company.zipcode = res.results.data['zipcode'];
            //             this.company.district = res.results.data['district'];
            //             this.company.branch_number = res.results.data['branch_number'];
            //             this.company.street_name = res.results.data['street_name'];
            //             this.company.building_no = res.results.data['building_no'];
            //             this.company.entity_name = res.results.data['entity_name'];
            //             this.company.entity_branch_number = res.results.data['entity_branch_number'];
            //             if(this.company.address == 'undefined' || this.company.address == ''){
            //                 this.company.address = this.company.building_no != '' ? this.company.building_no + "," : ''; 
            //                 this.company.address += this.company.street_name != '' ? this.company.street_name + " " : '';
            //                 this.company.address += (this.company.branch_number != '' && this.company.zipcode != '') ? this.company.branch_number + " - " + this.company.zipcode + ", " :'';
            //                 this.company.address += this.company.district + " - "+ this.company.city;
            //             }
            //         }
            //     }
            // )
        },
        getPyamentType(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getPayTypes');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.payTypeOp = [];
                for(let i=0;i<res.results.data.length;i++){
                    this.invoice.paytypes.push({
                        text: this.$parent.lang.langname == 'ar' ? res.results.data[i].type_name : res.results.data[i].type_name_en,
                        value: res.results.data[i].id
                    })
                }
                // this.paytypeOp = t;

                this.invoice.paytype = res.results.data[0].id;
            })
        },
        getCar(plate_number,index){
            plate_number = this.cars[index].plate_number;
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','carInfo');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[plate_number]',this.cars[index].plate_number);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    let cars = [];
                    if(res.error.number == 200){
                        let car = {
                            plate_number:res.results.data.plate_number,
                            made_year:res.results.data.made_year,
                            model:res.results.data.model,
                            vin:res.results.data.vin,
                            id:res.results.data.id,
                            color:res.results.data.color,
                            brand:res.results.data.brand,
                        }
                        this.newrow.plate_number = res.results.data.plate_number;
                        // this.updateCars(car);
                        this.cars[index] = car;
                        
                        this.addNewCar();
                        this.cars.pop()
                    }else{
                        this.newrow.plate_number = plate_number;
                        let car = {
                            plate_number:plate_number,
                            made_year:'',
                            model:'',
                            vin:'',
                            id:'',
                            color:'',
                            brand:'',
                        }
                        this.cars[index] = car;
                        this.addNewCar();
                        this.cars.pop()
                    }
                }
            )
        }
    },
    // watch: {
    //     // whenever question changes, this function will run
    //     cars(oldvalues, newvalues) {
    //         if (newvalues.includes('?')) {
    //             //this.getAnswer()
    //             this.updateCars()
    //             // console.log("oldvalue",oldvalues);
    //         }
    //     }
    // },
}
</script>
<style>
.dispFlex{
    display:flex;
    flex-direction: row;
    justify-content: center !important;
    padding-left:10px;
    padding-right:10px;
    justify-items: center;
    align-items: center;
}
.boDry:hover{
    background:green !important;
}
.addCar{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
.tobbuttom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.redColor {
    background: red!important;
    color: #fff!important;
}
.weightColor {
    background: #fff !important;
    color: #000 !important;
}
.borderRad_none, .borderRad_right {
    border-top-left-radius: 0 0!important;
    border-bottom-left-radius: 0 0!important;
    padding: 5px 10px!important;
    font-size: .8rem!important;
}
.hideme{
    display:none !important;
}
.borderRad_none {
    border-top-right-radius: 0 0!important;
    border-bottom-right-radius: 0 0!important;
}
.borderRad_left {
    border-top-left-radius: 5px 5px!important;
    border-bottom-left-radius: 5px 5px!important;
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
    padding: 5px 10px!important;
    font-size: .8rem!important;
    -webkit-margin-start: 5px!important;
    margin-inline-start: 5px!important;
}
</style>